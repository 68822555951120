<template>
  <v-main class="mt-5">
    <div class="mb-5 mt-5 pr-5 pl-5">
      <h2 class="mb-5 mt-5 pt-5">Service Directory</h2>
      <section v-if="errored">
        <p>
          We're sorry, we're not able to retrieve this information at the
          moment, please try back later
        </p>
      </section>
      <div
        style="position:absolute;margin:0 auto;left:50%,right:50%;"
        v-if="loading"
      >
        Loading...
      </div>
      <v-progress-circular
      :size="30"
      v-show="showProgress"
      color="primary"
      indeterminate
      ></v-progress-circular>
      <v-btn to="/createServiceDirectory" class="mb-5"> Create </v-btn>
      <v-row>
        <v-data-table
          v-if="!loading"
          :headers="headers"
          :items="serviceDirectory"
          :items-per-page="20"
          class="elevation-1"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="showDetail(item.id)"
            >
              mdi-pencil
            </v-icon>
            <br/>
            <v-icon
              small
              class="mr-2"
              @click="deleteDirectory(item.id)"
            >
              mdi-delete
            </v-icon>
            
          </template>
        </v-data-table>
      </v-row>
    </div>
  </v-main>
</template>

<script>
// @ is an alias to /src
// import { mapGetters } from "vuex";
import { DELETE_SERVICE_DIRECTORY, FETCH_SERVICE_DIRECTORY} from "../store/actions.type";
export default {
  name: "ServiceDirectory",
  data() {
    return {
      serviceDirectory: [],
      info: null,
      showProgress: false,
      loading: true,
      errored: false,
      headers: [
        { text: "Name", value: "name" },
        { text: "State/Region", value: "state_region" },
        { text: "Address", value: "address" },
        { text: "ContactNumbers", value: "contact_numbers" },
        { text: "Organization", value: "organization" },
        { text: "Opening Hours", value: "opening_hours" },
        { text: "Opening Days", value: "opening_days" },
        { text: "CreatedAt", value: "created_at" },
        { text: "UpdatedAt", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      json_fields: {
        "UserID":"userId",
        "createddate":"createddate",
        "MobileNo":"phone",
        "Gender":"gender",
        "Age":"age",
        "MaritalStatus":"marital_status",
        "StateRegion":"state_region",
        "UserType":"user_type",
        "ProviderType":"provider_type",
        "Organization":"organization",
        "PretestTaken":"pretestTaken",
        "PreTestAttemptDate":"preTestAttemptDate",
        "PreTestScore":"preTestScore",
        "Last_PostTestScore":"postTestScore",
        "PostTestPassedDate":"postTestPassedDate",
        "PostTestAttemptCount":"postTestAttemptCount"
      },
    };
  },
  computed: {},
  mounted() {
    this.getServiceDirectoryList({});
  },
  created() {},
  methods: {
    showDetail(serviceDirectoryId) {
      localStorage.setItem("selectedServiceDirectory", serviceDirectoryId);
        this.$router.push({
            name: "EditServiceDirectory",
            params: {
              serviceDirectoryId: serviceDirectoryId,
            },
          });
    },
    deleteDirectory(serviceDirectoryId) {
      if (serviceDirectoryId != "") {
        this.$store
          .dispatch(DELETE_SERVICE_DIRECTORY, { serviceDirectoryId: serviceDirectoryId })
          .then((data) => {
            this.loading = false;
            console.log(data);
            this.getServiceDirectoryList();
          })
          .catch((response) => {
            console.log(response);
            this.loading = false;
            this.errored = true;
          })
          .finally(() => (this.loading = false));
      }
    }, 
    getServiceDirectoryList(payload) {
      this.$store
        .dispatch(FETCH_SERVICE_DIRECTORY, payload)
        .then((data) => {
          this.loading = false;
          this.serviceDirectory = data.data.list;
          console.log(this.serviceDirectory);
        })
        .catch((response) => {
          console.log(response);
          this.loading = false;
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
          this.errored = false;
        });
    },
    fetchData(){
      this.showProgress = true;
      return this.serviceDirectory;
    },
    startDownload(){
        this.showProgress = true;
    },
    finishDownload(){
      this.showProgress = false;
    },
  },
};
</script>
